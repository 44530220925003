import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import api from '../utils/api';
import {
  Typography, Box, TextField, Autocomplete, Button, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Grid, FormControl, Select, MenuItem, InputLabel,
  CircularProgress, Card, CardContent, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import PageLayout from './PageLayout';

const ListDetail = () => {
  const { id } = useParams();
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [subcategories, setSubcategories] = useState(['All']);
  const [filterCategory, setFilterCategory] = useState(() => {
    return localStorage.getItem('listDetailFilterCategory') || 'All';
  });
  const [filterSubcategory, setFilterSubcategory] = useState(() => {
    return localStorage.getItem('listDetailFilterSubcategory') || 'All';
  });
  const [searchTerm, setSearchTerm] = useState(() => {
    return localStorage.getItem('listDetailSearchTerm') || '';
  });
  const [editingQuantity, setEditingQuantity] = useState({});
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [addQuantity, setAddQuantity] = useState(1);

  const fetchListDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/lists/${id}`);
      setList(response.data);

      const uniqueCategories = [...new Set(response.data.items.map(item => item.category).filter(Boolean))];
      setCategories(['All', ...uniqueCategories]);

      const allSubcategories = [...new Set(response.data.items.map(item => item.subcategory).filter(Boolean))];
      setSubcategories(['All', '(Blank)', ...allSubcategories]);
    } catch (error) {
      console.error('Error fetching list details:', error);
      setError('Failed to fetch list details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchListDetails();
    fetchProducts();
  }, [fetchListDetails]);

  useEffect(() => {
    localStorage.setItem('listDetailFilterCategory', filterCategory);
    localStorage.setItem('listDetailFilterSubcategory', filterSubcategory);
    localStorage.setItem('listDetailSearchTerm', searchTerm);
  }, [filterCategory, filterSubcategory, searchTerm]);

  const fetchProducts = async () => {
    try {
      const response = await api.get('/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const filteredSubcategories = useMemo(() => {
    if (!list || filterCategory === 'All') {
      return subcategories;
    }
    const filtered = ['All', '(Blank)', ...new Set(list.items
      .filter(item => item.category === filterCategory)
      .map(item => item.subcategory)
      .filter(Boolean))];
    return filtered;
  }, [filterCategory, list, subcategories]);

  const filteredItems = useMemo(() => {
    if (!list) return [];
    return list.items.filter(item =>
      (filterCategory === 'All' || item.category === filterCategory) &&
      (filterSubcategory === 'All' ||
        (filterSubcategory === '(Blank)' ? !item.subcategory : item.subcategory === filterSubcategory)) &&
      (!searchTerm ||
        (item.productName && item.productName.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.sku && item.sku.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  }, [list, filterCategory, filterSubcategory, searchTerm]);

  const totalQuantity = useMemo(() => {
    return filteredItems.reduce((sum, item) => sum + item.quantity, 0);
  }, [filteredItems]);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
    setSelectedProduct(null);
    setAddQuantity(1);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddProduct = async () => {
    if (!selectedProduct) return;
    try {
      await api.post(`/lists/${id}/items`, {
        productId: selectedProduct.id,
        quantity: addQuantity
      });
      fetchListDetails();
      setSelectedProduct(null);
      setAddQuantity(1);
    } catch (error) {
      console.error('Error adding product to list:', error);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    const filtered = products.filter(product =>
      product.product_name.toLowerCase().includes(newInputValue.toLowerCase()) ||
      product.sku.toLowerCase().includes(newInputValue.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleUpdateQuantity = async (productId, newQuantity) => {
    try {
      await api.put(`/lists/${id}/items/${productId}`, {
        quantity: newQuantity
      });
      fetchListDetails();
    } catch (error) {
      console.error('Error updating product quantity:', error);
    }
  };

  const handleRemoveProduct = async (productId) => {
    try {
      await api.delete(`/lists/${id}/items/${productId}`);
      fetchListDetails();
    } catch (error) {
      console.error('Error removing product from list:', error);
    }
  };

  const handleQuantityChange = (productId, value) => {
    setEditingQuantity(prev => ({ ...prev, [productId]: value }));
  };

  const handleQuantityBlur = (item) => {
    const newQuantity = parseInt(editingQuantity[item.productId], 10);
    if (!isNaN(newQuantity) && newQuantity !== item.quantity) {
      handleUpdateQuantity(item.productId, newQuantity);
    }
    setEditingQuantity(prev => ({ ...prev, [item.productId]: undefined }));
  };

  if (loading) {
    return (
      <PageLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <Typography color="error" variant="h6">{error}</Typography>
      </PageLayout>
    );
  }

  if (!list) {
    return (
      <PageLayout>
        <Typography variant="h6">No list found.</Typography>
      </PageLayout>
    );
  }

  return (
    <PageLayout>

      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Filters</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={filterCategory}
                  onChange={(e) => {
                    setFilterCategory(e.target.value);
                    setFilterSubcategory('All');
                  }}
                  label="Category"
                >
                  {categories.map(category => (
                    <MenuItem key={category} value={category}>
                      {category === 'All' ? <em>All</em> : category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Subcategory</InputLabel>
                <Select
                  value={filterSubcategory}
                  onChange={(e) => setFilterSubcategory(e.target.value)}
                  label="Subcategory"
                >
                  {filteredSubcategories.map(subcategory => (
                    <MenuItem key={subcategory} value={subcategory}>
                      {subcategory === 'All' || subcategory === '(Blank)' ? <em>{subcategory}</em> : subcategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          {list.name} <Typography component="span" color="text.secondary">(${list.totalValue.toFixed(2)} / Products: {filteredItems.length} / Items: {totalQuantity})</Typography>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddDialog}
        >
          Add Products
        </Button>
      </Box>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell>Product</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item) => (
              <TableRow key={item.productId} hover>
                <TableCell>{item.productName}</TableCell>
                <TableCell>{item.category}</TableCell>
                <TableCell>{item.subcategory}</TableCell>
                <TableCell>${item.unitPrice.toFixed(2)}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={editingQuantity[item.productId] !== undefined ? editingQuantity[item.productId] : item.quantity}
                    onChange={(e) => handleQuantityChange(item.productId, e.target.value)}
                    onBlur={() => handleQuantityBlur(item)}
                    InputProps={{ inputProps: { min: 0 } }}
                    size="small"
                  />
                </TableCell>
                <TableCell>${item.totalPrice.toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveProduct(item.productId)} size="small">
                    <DeleteIcon style={{ color: 'red' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Product to List</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Autocomplete
                options={products}
                getOptionLabel={(option) => `${option.product_name} (${option.sku})`}
                renderInput={(params) => <TextField {...params} label="Select Product" fullWidth />}
                value={selectedProduct}
                onChange={(event, newValue) => {
                  setSelectedProduct(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Quantity"
                type="number"
                value={addQuantity}
                onChange={(e) => setAddQuantity(Math.max(1, parseInt(e.target.value) || 0))}
                fullWidth
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog}>Cancel</Button>
          <Button
            onClick={handleAddProduct}
            variant="contained"
            color="primary"
            disabled={!selectedProduct}
          >
            Add to List
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default ListDetail;
