import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, Snackbar, Alert, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTenantTheme } from './hooks/useTenantTheme';
import api from './utils/api';
import { getMainUrl, getTenantUrl } from './utils/urlHelpers';
import AdminConfigPage from './components/AdminConfigPage';
import ProductList from './components/ProductList';
import ProductForm from './components/ProductForm';
import ListsOverview from './components/ListsOverview';
import ListDetail from './components/ListDetail';
import EditList from './components/EditList';
import TenantInfo from './components/TenantInfo';
import Splash from './components/Splash';
import Login from './components/Login';
import LandingPage from './components/LandingPage';
import Register from './components/Register';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);
  const [error, setError] = useState(null);
  const [isMainDomain, setIsMainDomain] = useState(false);
  const theme = useTenantTheme();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await api.get('/auth/verify');
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Token verification failed:', error);
          localStorage.removeItem('token');
        }
      }
      setIsLoading(false);
    };

    const checkDomain = () => {
      const currentUrl = new URL(window.location.href);
      const mainUrl = new URL(getMainUrl());
      setIsMainDomain(currentUrl.hostname === mainUrl.hostname);
    };

    checkAuth();
    checkDomain();
  }, []);

  useEffect(() => {
    const mainUrl = new URL(getMainUrl());
    const currentUrl = new URL(window.location.href);

    const isMain = currentUrl.hostname === mainUrl.hostname;
    setIsMainDomain(isMain);
  }, []);

  const checkBackendAvailability = async () => {
    try {
      await api.get('/health');
      setIsBackendAvailable(true);
      setError(null);
    } catch (error) {
      console.error('Backend health check failed:', error);
      setIsBackendAvailable(false);
      setError("We're experiencing technical difficulties. Please try again later.");
    }
  };

  useEffect(() => {
    checkBackendAvailability();
    const interval = setInterval(checkBackendAvailability, 30000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleUnauthorized = () => {
      console.log('Unauthorized event received');
      setIsAuthenticated(false);
      localStorage.removeItem('token');
      setError('Your session has expired. Please log in again.');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isBackendAvailable) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}>
            <Typography variant="h4" gutterBottom>
              Oops! Something went wrong.
            </Typography>
            <Typography variant="body1" gutterBottom>
              We're experiencing technical difficulties. Please try again later.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleRefresh}
              sx={{ mt: 2, padding: '10px 20px' }}
            >
              Refresh Page
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position="static">
            <Container maxWidth="xl">
              <Toolbar disableGutters>

                <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
                  <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                    CraftHub360
                  </Link>
                </Typography>

                {isMainDomain ? (
                  <>
                    <Button color="inherit" component={Link} to="/register">Register</Button>
                    <Button color="inherit" component={Link} to="/login">Login</Button>
                  </>
                ) : isAuthenticated ? (
                  <>
                    <Button color="inherit" component={Link} to="/settings">Settings</Button>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                  </>
                ) : (
                  <Button color="inherit" component={Link} to="/login">Login</Button>
                )}
              </Toolbar>
            </Container>
          </AppBar>
          <Container component="main" maxWidth="xl" sx={{ mt: 3, mb: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 1, flexGrow: 1 }}>
              <Routes>
                {isMainDomain ? (
                  <>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                  </>
                ) : (
                  <>
                    <Route path="/login" element={
                      !isAuthenticated ? (
                        <Login setIsAuthenticated={setIsAuthenticated} />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    } />
                    <Route
                      path="/*"
                      element={
                        <ProtectedRoute isAuthenticated={isAuthenticated}>
                          <Routes>
                            <Route index element={<Splash />} />
                            <Route path="products" element={<ProductList />} />
                            <Route path="products/add" element={<ProductForm />} />
                            <Route path="products/:id/edit" element={<ProductForm />} />
                            <Route path="lists" element={<ListsOverview />} />
                            <Route path="lists/:id" element={<ListDetail />} />
                            <Route path="lists/:id/edit" element={<EditList />} />
                            <Route path="settings" element={<AdminConfigPage />} />
                          </Routes>
                        </ProtectedRoute>
                      }
                    />
                  </>
                )}
              </Routes>
            </Box>
          </Container>
          <Box
            component="footer"
            sx={{
              py: 3,
              px: 2,
              mt: 'auto',
              backgroundColor: 'background.footer',
              borderTop: (theme) => `1px solid ${theme.palette.divider}`
            }}
          >
            <Container maxWidth="xl">
              <Typography variant="body2" color="text.footer" align="center">
                © {new Date().getFullYear()} CraftHub360. All rights reserved.
              </Typography>
            </Container>
          </Box>
        </Box>
      </Router>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

function ProtectedRoute({ isAuthenticated, children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
}

export default App;
