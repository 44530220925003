import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTenantTheme } from '../hooks/useTenantTheme';
import api from '../utils/api';

import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  TextField, Select, MenuItem, FormControl, InputLabel, Grid, Box, Checkbox,
  useMediaQuery, Tooltip, Typography, Card, CardContent, IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import PageLayout from './PageLayout';

function ProductList() {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [products, setProducts] = useState([]);
  const [productImages, setProductImages] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState(() => {
    return localStorage.getItem('filterCategory') || 'all';
  });
  const [filterSubcategory, setFilterSubcategory] = useState(() => {
    return localStorage.getItem('filterSubcategory') || 'all';
  });
  const [searchTerm, setSearchTerm] = useState(() => {
    return localStorage.getItem('searchTerm') || '';
  });
  const [sortField, setSortField] = useState(() => {
    return localStorage.getItem('sortField') || 'updated_at';
  });
  const [sortDirection, setSortDirection] = useState(() => {
    return localStorage.getItem('sortDirection') || 'desc';
  });

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState('');
  const [addToListDialogOpen, setAddToListDialogOpen] = useState(false);

  const filterAndSortProducts = useCallback(() => {
    let filtered = [...products];

    if (filterCategory !== 'all') {
      filtered = filtered.filter(product => product.category === filterCategory);
    }

    if (filterSubcategory !== 'all') {
      if (filterSubcategory === 'blank') {
        filtered = filtered.filter(product => !product.subcategory);
      } else {
        filtered = filtered.filter(product => product.subcategory === filterSubcategory);
      }
    }

    if (searchTerm) {
      filtered = filtered.filter(product =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.sku.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    filtered.sort((a, b) => {
      if (sortField === 'price') {
        const aPrice = Math.min(...(a.prices?.map(p => p.price) || [0]));
        const bPrice = Math.min(...(b.prices?.map(p => p.price) || [0]));
        return sortDirection === 'asc' ? aPrice - bPrice : bPrice - aPrice;
      }
      if (sortField === 'updated_at' || sortField === 'created_at') {
        return sortDirection === 'asc'
          ? new Date(a[sortField]) - new Date(b[sortField])
          : new Date(b[sortField]) - new Date(a[sortField]);
      }
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredProducts(filtered);
  }, [products, filterCategory, filterSubcategory, searchTerm, sortField, sortDirection]);

  const theme = useTenantTheme(); // Use the custom hook
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    filterAndSortProducts();
    localStorage.setItem('filterCategory', filterCategory);
    localStorage.setItem('filterSubcategory', filterSubcategory);
    localStorage.setItem('searchTerm', searchTerm);
    localStorage.setItem('sortField', sortField);
    localStorage.setItem('sortDirection', sortDirection);
  }, [filterCategory, filterSubcategory, searchTerm, sortField, sortDirection, filterAndSortProducts]);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await api.get('/products');
      setProducts(response.data);
      const uniqueCategories = [...new Set(response.data.map(product => product.category).filter(Boolean))];
      const sortedCategories = uniqueCategories.sort((a, b) => a.localeCompare(b));
      setCategories(sortedCategories);

      const allSubcategories = [...new Set(response.data.map(product => product.subcategory).filter(Boolean))];
      const sortedSubcategories = allSubcategories.sort((a, b) => a.localeCompare(b));
      setSubcategories(sortedSubcategories);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, []); // Empty dependency array as it doesn't rely on any props or state

  const fetchLists = useCallback(async () => {
    try {
      const response = await api.get('/lists');
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  }, []); // Empty dependency array as it doesn't rely on any props or state

  useEffect(() => {
    fetchProducts();
    fetchLists();
  }, [fetchProducts, fetchLists]);

  useEffect(() => {
    const fetchProductImages = async () => {
      const imagePromises = filteredProducts.map(async (product) => {
        if (product.images && product.images.length > 0) {
          try {
            const response = await api.get(`/products/${product.id}/images`);
            const images = response.data;

            // Prioritize detail image, fallback to thumbnail
            const imageUrl = images[0]?.detailUrl || images[0]?.thumbnailUrl || null;

            return {
              productId: product.id,
              imageUrl,
              thumbnailUrl: images[0]?.thumbnailUrl || null,
              detailUrl: images[0]?.detailUrl || null
            };
          } catch (error) {
            console.error(`Error fetching image for product ${product.id}:`, error);
            return {
              productId: product.id,
              imageUrl: null,
              thumbnailUrl: null,
              detailUrl: null
            };
          }
        }
        return {
          productId: product.id,
          imageUrl: null,
          thumbnailUrl: null,
          detailUrl: null
        };
      });

      const images = await Promise.all(imagePromises);
      const imageMap = images.reduce((acc, { productId, imageUrl, thumbnailUrl, detailUrl }) => {
        acc[productId] = { imageUrl, thumbnailUrl, detailUrl };
        return acc;
      }, {});

      setProductImages(imageMap);
    };

    fetchProductImages();
  }, [filteredProducts]);

  const openDeleteDialog = (product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProductToDelete(null);
  };

  const confirmDelete = async () => {
    if (!productToDelete) return;

    try {
      await api.delete(`/products/${productToDelete.id}`);
      fetchProducts();
      closeDeleteDialog();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleSort = (field) => {
    setSortDirection(sortField === field && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortField(field);
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setFilterCategory(newCategory);
    setFilterSubcategory('all');
  };

  const availableSubcategories = useMemo(() => {
    if (filterCategory === 'all') return subcategories;
    const filteredSubcategories = products
      .filter(product => product.category === filterCategory)
      .map(product => product.subcategory)
      .filter(Boolean);
    return ['blank', ...new Set(filteredSubcategories)].sort((a, b) => a.localeCompare(b));
  }, [products, filterCategory, subcategories]);

  const handleProductSelect = (productId) => {
    setSelectedProducts(prev =>
      prev.includes(productId)
        ? prev.filter(id => id !== productId)
        : [...prev, productId]
    );
  };

  const handleAddToList = async () => {
    if (!selectedList || selectedProducts.length === 0) return;

    try {
      const listResponse = await api.get(`/lists/${selectedList}`);
      const currentList = listResponse.data;

      const updatedItems = [
        ...(currentList.items || []),
        ...selectedProducts.map(productId => ({
          productId: productId,
          quantity: 1
        }))
      ];

      await api.put(`/lists/${selectedList}`, {
        name: currentList.name,
        items: updatedItems
      });

      setSelectedProducts([]);
      setSelectedList('');
      setAddToListDialogOpen(false);
    } catch (error) {
      console.error('Error adding products to list:', error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <PageLayout>

      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the product "{productToDelete?.product_name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Filters</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  value={filterCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Subcategory</InputLabel>
                <Select
                  value={filterSubcategory}
                  onChange={(e) => setFilterSubcategory(e.target.value)}
                  label="Subcategory"
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="blank">
                    <em>(Blank)</em>
                  </MenuItem>
                  {availableSubcategories.map((subcategory) => (
                    subcategory !== 'blank' && (
                      <MenuItem key={subcategory} value={subcategory}>{subcategory}</MenuItem>
                    )
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body1">Total Products: {filteredProducts.length}</Typography>
        <Box sx={{ mb: 2, display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <Button
            component={Link}
            to="/products/add"
            variant="contained"
            color="primary"
            fullWidth={isMobile}
          >
            Create New Product
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddToListDialogOpen(true)}
            disabled={selectedProducts.length === 0}
            fullWidth={isMobile}
          >
            Add Selected to List
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell padding="checkbox">
                <Checkbox
                  onChange={(e) =>
                    setSelectedProducts(e.target.checked ? filteredProducts.map(p => p.id) : [])
                  }
                  checked={selectedProducts.length === filteredProducts.length}
                  indeterminate={selectedProducts.length > 0 && selectedProducts.length < filteredProducts.length}
                />
              </TableCell>
              {!isMobile && <TableCell style={{ width: '60px' }}></TableCell>}
              <TableCell onClick={() => handleSort('product_name')}>Name</TableCell>
              {!isMobile && <TableCell onClick={() => handleSort('category')}>Category</TableCell>}
              {!isMobile && <TableCell onClick={() => handleSort('subcategory')}>Subcategory</TableCell>}
              <TableCell onClick={() => handleSort('price')}>Price</TableCell>
              {!isMobile && <TableCell onClick={() => handleSort('created_at')}>Created</TableCell>}
              {!isMobile && <TableCell onClick={() => handleSort('updated_at')}>Updated</TableCell>}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow hover key={product.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedProducts.includes(product.id)}
                    onChange={() => handleProductSelect(product.id)}
                  />
                </TableCell>
                {!isMobile && (
                  <TableCell style={{ width: '60px', height: '60px' }}>
                    {productImages[product.id]?.imageUrl && (
                      <img
                        src={productImages[product.id].imageUrl}
                        alt={product.product_name}
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  <Tooltip title={`SKU: ${product.sku}`} arrow>
                    <Typography variant="body2">{product.product_name}</Typography>
                  </Tooltip>
                </TableCell>
                {!isMobile && <TableCell>{product.category}</TableCell>}
                {!isMobile && <TableCell>{product.subcategory}</TableCell>}
                <TableCell>
                  {product.prices && product.prices.length > 0 ? (
                    Math.min(...product.prices.map(p => p.price)) === Math.max(...product.prices.map(p => p.price))
                      ? `$${Math.min(...product.prices.map(p => p.price)).toFixed(2)}`
                      : `$${Math.min(...product.prices.map(p => p.price)).toFixed(2)} - $${Math.max(...product.prices.map(p => p.price)).toFixed(2)}`
                  ) : 'N/A'}
                </TableCell>
                {!isMobile && <TableCell>{formatDate(product.created_at)}</TableCell>}
                {!isMobile && <TableCell>{formatDate(product.updated_at)}</TableCell>}
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 1 }}>
                    <IconButton
                      component={Link}
                      to={`/products/${product.id}/edit`}
                      size="small"
                      color="primary"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => openDeleteDialog(product)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={addToListDialogOpen} onClose={() => setAddToListDialogOpen(false)}>
        <DialogTitle>Add to List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a list to add the selected products to:
          </DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>List</InputLabel>
            <Select
              value={selectedList}
              onChange={(e) => setSelectedList(e.target.value)}
            >
              {lists.map((list) => (
                <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddToListDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddToList} color="primary" variant="contained">
            Add to List
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default ProductList;
