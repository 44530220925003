import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import api from '../utils/api';
import {
  Typography, Box, TextField, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, IconButton, Grid,
  FormControl, InputLabel, Select, MenuItem, Card, CardContent,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import PageLayout from './PageLayout';

const ListsOverview = () => {
  const [lists, setLists] = useState([]);
  const [filteredLists, setFilteredLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const fetchLists = useCallback(async () => {
    try {
      const response = await api.get('/lists');
      setLists(response.data);
      setFilteredLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  }, []);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  const handleCreateList = async () => {
    try {
      await api.post('/lists', { name: newListName });
      setNewListName('');
      setOpenDialog(false);
      fetchLists();
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleDeleteList = async (id) => {
    try {
      await api.delete(`/lists/${id}`);
      fetchLists();
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === 'asc';
    setSortField(field);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  useEffect(() => {
    const filtered = lists.filter(list =>
      list.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sorted = filtered.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredLists(sorted);
  }, [lists, searchTerm, sortField, sortDirection]);

  return (
    <PageLayout>

      <Card elevation={3} sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Filters</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="body1">Total Lists: {filteredLists.length}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Create New List
        </Button>
      </Box>

      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell onClick={() => handleSort('name')}>Name</TableCell>
              <TableCell onClick={() => handleSort('uniqueItemCount')}>Unique Products</TableCell>
              <TableCell onClick={() => handleSort('totalQuantity')}>Total Items</TableCell>
              <TableCell onClick={() => handleSort('totalValue')}>Total Value</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLists.map((list) => (
              <TableRow key={list.id} hover>
                <TableCell component={Link} to={`/lists/${list.id}`}>
                  <Typography variant="body2">{list.name}</Typography>
                </TableCell>
                <TableCell>{list.uniqueItemCount}</TableCell>
                <TableCell>{list.totalQuantity}</TableCell>
                <TableCell>${list.totalValue}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      component={Link}
                      to={`/lists/${list.id}/edit`}
                      size="small"
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDeleteList(list.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for your new list:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateList} color="primary" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
};

export default ListsOverview;
